import { BrowserClient, createBoundLocalization, LocalizationProvider } from '@wf-mfe/localize-react';

const redrockClient = new BrowserClient({
  namespace: 'redrock',
  enableTerminology: true
});

export const LOCALE_CODE = redrockClient.getLocale();

redrockClient.loadTerminologyLabels().then(labels => {
  redrockClient.setTerminologyLabels(labels);
});

const {
  LoadLocalizations,
  Localization,
  useLocalization,
  useLocalizations
} = createBoundLocalization(redrockClient);

export {
  LoadLocalizations,
  Localization,
  useLocalization,
  useLocalizations,
  LocalizationProvider,
  redrockClient
};
