import React, { Component } from 'react';
import { observer } from 'mobx-react';
import Settings from '../header/settings/Settings';
import ExportToExcel from './ExportToExcel';
import FullModeToggle from './FullModeToggle';
import VisualizationButton from './VisualizationButton';
import { loadingStore } from '../../stores/LoadingStore';
import ViewModeStore from '../../stores/ViewModeStore';
import ScrollStore from '../../stores/ScrollStore';
import If from 'If';
import UniqueUrlGenerator from './uniqueUrlGenerator/UniqueUrlGenerator';
import { ViewEnum } from '../../constants/ViewEnums';
import HelpIcon from 'phoenix-icons/dist/HelpIcon.js';
import { Tip } from 'titan/react-popover';
import { standardTooltipOptions } from '../../constants/TooltipOptions';
import { redrockClient, LOCALE_CODE } from '../../../localization/localization';

const chineseLocales = {
  'zh-CN': 'zh-hans',
  'zh-TW': 'zh-hant',
  'zh-HANS': 'zh-hans',
  'zh-HANT': 'zh-hant',
};

@observer
export default class HeaderRight extends Component {
  // noinspection JSAnnotator
  props: {
    loadStoreData: Function,
    disableCondition: Boolean,
    loadVisualizationData: Function,
    fullModeToggle: Function,
    intervalStore: Object,
    treeNodeStore: Object,
    isFilterVisible: Boolean,
    filterStore: Object,
    showSaveDialog: Function,
    mfeMigrationEnabled: ?Boolean,
  };

  render() {
    const {
      loadStoreData,
      disableCondition,
      loadVisualizationData,
      fullModeToggle,
      intervalStore,
      treeNodeStore,
      isFilterVisible,
      filterStore,
      showSaveDialog,
    } = this.props;

    const locale = LOCALE_CODE.startsWith('zh') ? chineseLocales[LOCALE_CODE] : LOCALE_CODE.split('-')[0] || 'en';

    return (
      <div className="resource-planner-header-right">
        <ExportToExcel
          treeNodeStore={treeNodeStore}
          filterStore={filterStore}
          intervalStore={intervalStore}
          showSaveDialog={showSaveDialog}
          disabled={loadingStore.noResults || disableCondition || loadingStore.isLazyLoadingMode}
        />
        <If condition={ViewModeStore.visualizationVisible}>
          <VisualizationButton
            disabled={disableCondition || loadingStore.noResults}
            loadVisualizationData={loadVisualizationData}
            intervalStore={intervalStore}
          />
        </If>
        {ViewModeStore.activeView.key === ViewEnum.user && ViewModeStore.urlGeneratorVisible && (
          <UniqueUrlGenerator
            filterStore={filterStore}
            disabled={disableCondition}
            mfeMigrationEnabled={this.props.mfeMigrationEnabled}
          />
        )}
        <If condition={ViewModeStore.settingsVisible}>
          <Settings
            onSaveEventHandler={loadStoreData}
            disabled={disableCondition || loadingStore.isLazyLoadingMode}
            treeNodeStore={treeNodeStore}
          />
        </If>

        <If condition={ScrollStore.scrollStore.fullModeButtonVisible}>
          <FullModeToggle
            disabled={disableCondition}
            fullModeToggle={fullModeToggle}
            isFilterVisible={isFilterVisible}
          />
        </If>

        <If condition={ViewModeStore.isQuickSilver}>
          <Tip
            {...standardTooltipOptions}
            content={
              <span>{redrockClient.getTextSync('resourceplanner.context.sensitive.help')}</span>
            }
          >
            <div className="rp-help-icon">
              <a
                href={
                  `https://experienceleague.adobe.com/${locale}/docs/workfront/using/manage-resources/resource-planning-in-adobe-workfront/resource-planner-navigation`
                }
                data-testid={'context-sensitive-help'}
                aria-label={redrockClient.getTextSync('resourceplanner.context.sensitive.help')}
                target="_blank"
              >
                <HelpIcon />
              </a>
            </div>
          </Tip>
        </If>
      </div>
    );
  }
}
